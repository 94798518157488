import { PRICE_UNITS_ENUM_VALUES } from '@scripts/enums';
import { formatPrice } from '@scripts/helpers';
import { useMedia } from '@scripts/hooks';

import {
    productStoresCardContainerCodeStyle,
    productStoresCardContainerPriceCodeStyle,
    productStoresCardContainerPriceItemCodeStyle,
    productStoresCardOldPriceCodeStyle,
    productStoresCardPercentEconomyCodeStyle,
    productStoresCardVatCodeStyle,
} from './styles';
import { PriceItemStore } from './types';

const PriceItem = ({
    vat,
    priceUnit,
    price,
    oldPrice,
    product,
    isPhysical,
    primaryColorPrice,
    isCheckout = false,
}: PriceItemStore) => {
    const { xs } = useMedia();
    const percentEconomy = product && (product.personal_price?.discount_percent || product.base_price.discount_percent);
    const isCardVat = !isPhysical && vat >= 1 && !isCheckout;
    const isPercentEconomy = percentEconomy !== undefined && percentEconomy < 0;

    const marginOfBottom = () => {
        switch (true) {
            case isCheckout:
                return '0px';
            case isPhysical || vat < 1:
                return '20px';
            default:
                return '5px';
        }
    };

    return (
        <div>
            <div css={productStoresCardContainerCodeStyle(xs, marginOfBottom)}>
                <div css={productStoresCardContainerPriceItemCodeStyle(primaryColorPrice || '')}>
                    {formatPrice(price)} ₽{!isCheckout && '/'}
                    {priceUnit && (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: PRICE_UNITS_ENUM_VALUES[priceUnit],
                            }}
                        ></span>
                    )}
                </div>
                <div css={productStoresCardContainerPriceCodeStyle}>
                    {oldPrice > price && (
                        <>
                            <div css={productStoresCardOldPriceCodeStyle(xs)}>
                                {formatPrice(oldPrice)} ₽{!isCheckout && '/'}
                                {priceUnit && (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: PRICE_UNITS_ENUM_VALUES[priceUnit],
                                        }}
                                    ></span>
                                )}
                            </div>
                            {isPercentEconomy && (
                                <div css={productStoresCardPercentEconomyCodeStyle}>{percentEconomy}%</div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {isCardVat && <div css={productStoresCardVatCodeStyle}>НДС: {vat}%</div>}
        </div>
    );
};

export default PriceItem;
