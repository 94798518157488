import { ProductDetail } from '@api/catalog';

import Picture from '@components/controls/future/Picture';

import { CustomersTypeEnum } from '@scripts/enums';
import { colors, scale, typography } from '@scripts/gds';
import { fromKopecksToRouble, transformImageUrl } from '@scripts/helpers';
import { useMedia } from '@scripts/hooks';
import { useGetUserData } from '@scripts/hooks/useGetUserData';

import { customPersonalDiscount, personalPrice } from '../helper';
import PriceItem from './PriceItem';
import {
    productStoresCardContainerBodyDisplayStyle,
    productStoresCardContainerBodyStyle,
    productStoresCardContainerBodyTextBrandCodeStyle,
    productStoresCardContainerBodyTextBrandStyle,
    productStoresCardContainerBodyTextStyle,
    productStoresCardContainerStyle,
    productStoresCardImageContainerStyle,
    productStoresCardImageStyle,
    productStoresCardNameCss,
} from './styles';
import { ProductStoreCardProps } from './types';

const ProductStoreCard = ({ productItem }: ProductStoreCardProps) => {
    const { md, sm, lg } = useMedia();

    const { name, main_image } = productItem as ProductDetail;

    const { userData } = useGetUserData();
    const typeShowcase =
        productItem &&
        productItem.discounts &&
        productItem.discounts.filter(discount => discount.variant === 1).length > 0;

    const hasPersonalPrice = productItem?.has_personal_price || productItem?.personal_price;

    const isPhysical = userData?.customer_type === CustomersTypeEnum.PHISICAL;

    const primaryColorPrice = `${
        (!userData &&
            productItem &&
            productItem.discounts &&
            productItem.discounts.filter(discount => discount.variant === 1).length > 0) ||
        ((userData?.customer_type === CustomersTypeEnum.ENTITY || userData?.customer_type === CustomersTypeEnum.IP) &&
            ((productItem && productItem.has_personal_price) ||
                (productItem &&
                    productItem.discounts &&
                    productItem.discounts.filter(discount => discount.variant === 1).length > 0))) ||
        (userData?.customer_type === CustomersTypeEnum.PHISICAL &&
            productItem &&
            productItem.discounts &&
            productItem.discounts.filter(discount => discount.variant === 1).length > 0)
            ? colors.primary
            : colors.textMainBlack
    }`;

    const pictureSources = [
        {
            media: '',
            image: transformImageUrl(main_image, scale(10), scale(10)),
        },
    ];

    return (
        <div css={productStoresCardContainerStyle(md)}>
            <div css={productStoresCardImageContainerStyle}>
                {main_image && (
                    <Picture
                        sources={pictureSources}
                        loading="lazy"
                        alt={name}
                        width={scale(10)}
                        height={scale(10)}
                        style={productStoresCardImageStyle}
                    />
                )}
            </div>

            <div css={productStoresCardContainerBodyStyle(md, lg)}>
                <div css={typography('bodySmSemiBold')}>
                    <p css={productStoresCardNameCss}>{name}</p>

                    <div css={productStoresCardContainerBodyDisplayStyle}>
                        <div css={productStoresCardContainerBodyTextStyle(sm)}>
                            <div>
                                <span css={productStoresCardContainerBodyTextBrandStyle}>Продавец: </span>
                                <span css={productStoresCardContainerBodyTextBrandCodeStyle}>
                                    {productItem.seller?.name}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PriceItem
                vat={productItem.vat}
                priceUnit={productItem.price_unit}
                price={fromKopecksToRouble(
                    personalPrice({
                        hasPersonalPrice,
                        userData,
                        typeShowcase,
                        productDataState: productItem,
                    })
                )}
                oldPrice={fromKopecksToRouble(productItem.base_price?.cost)}
                product={productItem}
                isPhysical={isPhysical}
                userData={userData}
                customPersonalDiscount={customPersonalDiscount({
                    hasPersonalPrice,
                    userData,
                    typeShowcase,
                    productDataState: productItem,
                })}
                primaryColorPrice={primaryColorPrice}
            />
        </div>
    );
};

export default ProductStoreCard;
