import { CurrentUser } from '@api/auth/types';
import { ProductDetail, ProductOffer } from '@api/catalog';

import { CustomersTypeEnum } from '@scripts/enums';

interface PersonalPrice {
    userData: CurrentUser | undefined;
    hasPersonalPrice:
        | true
        | {
              price: number;
              cost: number;
              discount_percent: number;
          }
        | undefined;
    typeShowcase: boolean | undefined;
    productDataState: ProductDetail;
}

const calculateDiscountPercentage = (cost: number, price: number): number =>
    Math.floor(Number((((cost - price) / cost) * 100).toFixed(2)));

export const personalPrice = ({
    userData,
    productDataState,
    hasPersonalPrice,
    typeShowcase,
}: PersonalPrice): number => {
    if ((!userData || userData?.customer_type === CustomersTypeEnum.PHISICAL) && hasPersonalPrice && typeShowcase) {
        return productDataState?.base_price?.price || 0;
    }
    if ((!userData || userData?.customer_type === CustomersTypeEnum.PHISICAL) && hasPersonalPrice && !typeShowcase) {
        return productDataState?.base_price?.cost || 0;
    }
    if (!hasPersonalPrice && typeShowcase) {
        return productDataState?.base_price?.price || 0;
    }

    if (!hasPersonalPrice && !typeShowcase) {
        return productDataState?.base_price?.cost || 0;
    }
    if (
        (userData?.customer_type === CustomersTypeEnum.ENTITY || userData?.customer_type === CustomersTypeEnum.IP) &&
        hasPersonalPrice &&
        typeShowcase
    ) {
        return productDataState?.personal_price
            ? productDataState?.personal_price?.price
            : productDataState?.base_price.price; // TODO sm-982
    }
    if (
        (userData?.customer_type === CustomersTypeEnum.ENTITY || userData?.customer_type === CustomersTypeEnum.IP) &&
        hasPersonalPrice &&
        !typeShowcase
    ) {
        return productDataState?.personal_price
            ? productDataState?.personal_price?.cost
            : productDataState?.base_price.cost || 0; // TODO sm-982
    }

    return 0;
};

export const customPersonalDiscount = ({
    userData,
    productDataState,
    hasPersonalPrice,
    typeShowcase,
}: PersonalPrice): number => {
    if (!userData && (typeShowcase || hasPersonalPrice)) return productDataState?.base_price?.discount_percent || 0;

    if (
        (userData?.customer_type === CustomersTypeEnum.ENTITY || userData?.customer_type === CustomersTypeEnum.IP) &&
        hasPersonalPrice &&
        productDataState &&
        productDataState.personal_price
    ) {
        // TODO sm-982
        return productDataState?.personal_price
            ? calculateDiscountPercentage(productDataState.personal_price.cost, productDataState.personal_price.price)
            : calculateDiscountPercentage(productDataState.base_price.cost, productDataState.base_price.price);
    }
    if (
        (userData?.customer_type === CustomersTypeEnum.PHISICAL ||
            userData?.customer_type === CustomersTypeEnum.ENTITY ||
            userData?.customer_type === CustomersTypeEnum.IP) &&
        (typeShowcase || hasPersonalPrice)
    )
        return productDataState?.base_price?.discount_percent || 0;

    return 0;
};

export const getStoreCity = (offer: ProductOffer) => {
    return (
        offer.store.address.address_string
            .split(',')[0]
            .replace(/^\s*(г|город)\s+/i, '')
            .trim() ||
        (offer && offer.store.address.address_string.trim())
    );
};
