import { CSSObject } from '@emotion/core';

import { colors, scale, typography } from '@scripts/gds';

export const productStoresCardContainerStyle = (md: string): CSSObject => ({
    display: 'flex',
    gap: scale(4),
    padding: '30px',
    borderBottom: `1px solid ${colors.borderMain}`,
    [md]: { paddingRight: 0 },
});

export const productStoresCardImageContainerStyle: CSSObject = {
    width: scale(10),
    height: scale(10),
    minWidth: scale(10),
    minHeight: scale(10),
    position: 'relative',
};

export const productStoresCardImageStyle: CSSObject = {
    objectFit: 'contain',
    height: scale(10),
};

export const productStoresCardContainerBodyStyle = (md: string, lg: string): CSSObject => ({
    width: '45%',
    [md]: { width: `calc(100% - ${scale(12)}px)` },
    [lg]: { marginRight: 0 },
});

export const productStoresCardNameCss = (): CSSObject => ({
    overflow: 'hidden',
    width: '100%',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3,
    ...typography('bodyMdMedium'),
});

export const productStoresCardContainerBodyDisplayStyle: CSSObject = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: scale(2),
};

export const productStoresCardContainerBodyTextStyle = (sm: string): CSSObject => ({
    ...typography('bodySmRegular'),
    display: 'flex',
    flexDirection: 'column',
    [sm]: {
        width: 'auto',
    },
});

export const productStoresCardContainerBodyContainerArticleStyle: CSSObject = {
    marginBottom: `${scale(3, true)}px`,
};

export const productStoresCardContainerBodyTextArticleStyle: CSSObject = {
    marginRight: scale(1),
    color: colors.textSecondGrey,
};
export const productStoresCardContainerBodyTextArticleCodeStyle: CSSObject = {
    ...typography('bodyXSRegular'),
    overflowWrap: 'anywhere',
};

export const productStoresCardContainerBodyTextBrandStyle: CSSObject = {
    marginRight: scale(1),
    color: colors.textSecondGrey,
};

export const productStoresCardContainerBodyTextBrandCodeStyle: CSSObject = {
    overflowWrap: 'anywhere',
};

export const productStoresCardContainerCodeStyle = (
    xs: string,
    marginOfBottom: () => '5px' | '20px' | '0px'
): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap',
    [xs]: { marginBottom: '5px' },
    marginBottom: marginOfBottom(),
});

export const productStoresCardContainerPriceItemCodeStyle = (primaryColorPrice: string): CSSObject => ({
    color: primaryColorPrice,
    ...typography('bodyMdMedium'),
    lineHeight: '19.2px',
    textWrap: 'nowrap',
});

export const productStoresCardContainerPriceCodeStyle: CSSObject = {
    textWrap: 'nowrap',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
};

export const productStoresCardOldPriceCodeStyle = (xs: string): CSSObject => ({
    position: 'relative',
    color: colors.textSecondGrey,
    ...typography('bodySmRegular'),
    display: 'flex',
    alignItems: 'center',
    textWrap: 'nowrap',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '-1px',
        height: '1px',
        width: 'calc(100% + 2px)',
        backgroundColor: colors.textSecondGrey,
    },
    [xs]: {
        ...typography('bodySmRegular'),
        lineHeight: '16.8px',
    },
});

export const productStoresCardPercentEconomyCodeStyle: CSSObject = {
    backgroundColor: colors.primary,
    color: colors.white,
    borderRadius: scale(5),
    marginLeft: scale(1, true),
    ...typography('personalDiscount'),
    padding: `${scale(1, true)}px ${scale(1)}px`,
};

export const productStoresCardVatCodeStyle: CSSObject = {
    ...typography('bodySmRegular'),
    color: colors.textSecondGrey,
};
