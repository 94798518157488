import { CSSObject } from '@emotion/core';

import { colors, scale, typography } from '@scripts/gds';

export const productStoresPopupStyle = (md: string, xs: string): CSSObject => ({
    maxWidth: '1200px',
    width: '70vw',

    [md]: { height: 'fit-content' },
    [xs]: { height: '100%' },
});

export const productStoresHeaderStyle = (sm: string): CSSObject => ({
    display: 'flex',
    padding: `30px 30px ${scale(5, true)}px`,
    [sm]: {
        padding: `0 30px ${scale(5, true)}px`,
    },
});

export const productStoresCostStyle: CSSObject = {
    paddingLeft: `${scale(2)}px`,
    color: colors.textLight,
};

export const productStoresDescriptionStyle = (sm: string): CSSObject => ({
    margin: `0 ${scale(5, true)}px ${scale(5, true)}px`,
    padding: `${scale(1)}px ${scale(3)}px`,
    backgroundColor: colors.backgroundMain,
    color: colors.textSecondGrey,
    lineHeight: '22.4px',
    ...typography('bodySm'),
    [sm]: {
        textAlign: 'center',
    },
});

export const productStoresContainerListStoreStyle: CSSObject = {
    marginBottom: scale(5),
};

export const popupContainerStoresStyle = (matches: boolean) =>
    (matches && {
        overflowY: 'auto',
        content: {
            marginTop: 'auto',
            paddingBottom: '0 !important',
        },
    }) ||
    {};

export const productStoresPopupBodyStyle = (): CSSObject => ({
    padding: 0,
});

export const productStoresPopupBodyContainerStyle: CSSObject = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
};

export const productStoresPopupBodyContainerContainerStyle: CSSObject = {
    flex: 1,
    width: '100%',
};

export const productStoresPopupBodyContainerContainerContainerStyle: CSSObject = { width: '100%' };

export const productStoresPopupBodyContainerBodyStyle = (md: string, xs: string): CSSObject => ({
    display: 'flex',
    width: '100%',
    position: 'relative',
    padding: `30px`,
    backgroundColor: colors.backgroundMain,
    borderRadius: `0 0 ${scale(5, true)}px ${scale(5, true)}px`,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    [md]: { maxWidth: '100%', order: 3 },
    [xs]: { borderRadius: '0px', padding: scale(3, true) },
});

export const productStoresFormSubmitStyle = (md: string, sm: string): CSSObject => ({
    padding: `14px ${scale(3)}px`,

    [md]: {
        position: 'initial',
        width: '100%',
        height: '100%',
    },
    [sm]: { width: 'auto' },
});

export const storeCardContainer: CSSObject = {
    padding: `0 ${scale(5, true)}px ${scale(3, true)}px`,
};

export const storeAddressContainer: CSSObject = {
    ...typography('bodySmMedium'),
    margin: 'auto 0',
};

export const storeCounterContainer = (smMin: string, sm: string): CSSObject => ({
    [smMin]: {
        textAlign: 'center',
    },
    [sm]: {
        maxWidth: scale(25),
    },
});

export const storeCardContainerContainer = (sm: string): CSSObject => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 182px',
    justifyContent: 'space-between',
    padding: `${scale(3, true)}px ${scale(5, true)}px`,
    border: `1px solid var(--lines-dop, rgba(201, 209, 214, 1))`,
    borderRadius: '6px',
    [sm]: {
        gridTemplateColumns: '1fr',
        gap: scale(1),
    },
});

export const storeCardContainerBody = (isPreOrder: boolean): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    gap: '10px',
    svg: {
        transition: '0.2s',
        filter: isPreOrder
            ? 'brightness(0) saturate(100%) invert(17%) sepia(87%) saturate(2285%) hue-rotate(213deg) brightness(101%) contrast(96%)'
            : 'brightness(0) saturate(100%) invert(46%) sepia(78%) saturate(3273%) hue-rotate(102deg) brightness(100%) contrast(92%)',
    },
});
