import { useCallback, useMemo } from 'react';

import { useBasketAddProduct, useGetBasket } from '@api/basket';

import { OfferCardItem, useCartContext } from '@context/cart';

import { useGetUserData } from './useGetUserData';

export const useMassAddToBasketHandler = () => {
    const { user } = useGetUserData();

    const { basketProducts, setBasketProducts } = useCartContext();

    const { mutateAsync: setProduct } = useBasketAddProduct();
    const { data: apiBasketData } = useGetBasket(Boolean(user));
    const basketData = useMemo(() => apiBasketData?.data || [], [apiBasketData]);

    return useCallback(
        async (selectedProducts: OfferCardItem[], setIsLoadingBasket?: (val: boolean) => void) => {
            let updatedProducts = [...basketProducts];

            const productsToUpdate: { offer_id: number; qty: number }[] = [];

            selectedProducts.forEach(cartItem => {
                const { offer_id, qty } = cartItem;

                if (qty === 0) {
                    // Обрабатываем удаление товара из корзины, если кол-во = 0
                    if (user) {
                        productsToUpdate.push({ offer_id, qty: 0 });
                    } else {
                        updatedProducts = updatedProducts.filter(item => item.offer_id !== offer_id);
                    }

                    return;
                }

                if (offer_id) {
                    if (user) {
                        // Добавляем или обновляем товар для API
                        productsToUpdate.push({ offer_id, qty });
                    } else {
                        // Локальное обновление корзины для неавторизованного пользователя
                        const existingItem = updatedProducts.find(item => item.offer_id === offer_id);

                        if (existingItem) {
                            updatedProducts = updatedProducts.map(item =>
                                item.offer_id === offer_id
                                    ? {
                                          ...item,
                                          qty,
                                      }
                                    : item
                            );
                        } else {
                            updatedProducts.push({ ...cartItem, qty });
                        }
                    }
                }
            });

            // Добавляем для авторизованных пользователей
            if (user && productsToUpdate.length > 0) {
                if (setIsLoadingBasket) setIsLoadingBasket(true);
                await setProduct(productsToUpdate).finally(() => {
                    if (setIsLoadingBasket) setIsLoadingBasket(false);
                });
            }

            setBasketProducts(updatedProducts);
        },
        [user, basketData, basketProducts]
    );
};
