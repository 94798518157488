import { useCallback, useMemo } from 'react';
import Media from 'react-media';

import { BasketProduct } from '@api/basket/types';

import { useAuth } from '@context/auth';
import { OfferCardItem } from '@context/cart';

import { getStoreCity } from '@views/product/components/helper';

import Counter from '@components/controls/Counter';

import { PRICE_UNITS, PRICE_UNITS_ENUM_VALUES } from '@scripts/enums';
import { useTheme } from '@scripts/gds';
import { declOfNum, fromKopecksToRouble } from '@scripts/helpers';
import { useMedia } from '@scripts/hooks';

import CheckmarkBag from '@icons/small/checkmarkBag.svg';
import ReturnedBag from '@icons/small/returnedBag.svg';

import {
    storeAddressContainer,
    storeCardContainer,
    storeCardContainerBody,
    storeCardContainerContainer,
    storeCounterContainer,
} from './styles';
import { StoreCartProps } from './type';

const StoreCard = ({
    productDataState,
    productsBasket,
    offer,
    isPreOrder,
    setIsAddProducts,
    setSelectedProduct,
}: StoreCartProps) => {
    const { isAuthUser } = useAuth();
    const { layout } = useTheme();
    const { sm, smMin } = useMedia();
    const { id, multiple, quantity, price_unit } = productDataState;

    const productBasket = useMemo(() => {
        if (isAuthUser) {
            return (productsBasket as OfferCardItem[])?.find(product => product.id === offer?.id);
        }

        return (productsBasket as BasketProduct[])?.find(product => product.offer_id === offer?.id);
    }, [isAuthUser, productsBasket]);

    const productBasketQty = useMemo(() => {
        return productBasket?.qty || 0;
    }, [productBasket]);

    const updateSelectedProduct = (newItem: OfferCardItem) => {
        if (setSelectedProduct) {
            setSelectedProduct(prevItems => {
                const exists = prevItems.some(item => item.offer_id === newItem.offer_id);

                if (exists) {
                    return prevItems.map(item => (item.offer_id === newItem.offer_id ? newItem : item));
                }

                return [...prevItems, newItem];
            });
        }
    };

    const changeQtyHandler = useCallback(
        (value: number) => {
            setIsAddProducts(true);

            updateSelectedProduct({
                ...productDataState,
                qty: value,
                offer_id: offer.id,
                store_city: offer?.store?.address?.address_string ? getStoreCity(offer) : '',
            });
        },
        [isAuthUser, id]
    );

    return (
        <div css={storeCardContainer}>
            <div css={storeCardContainerContainer(sm)}>
                <div css={storeAddressContainer}>Склад г. {getStoreCity(offer)}</div>
                <div css={storeCardContainerBody(isPreOrder)}>
                    {isPreOrder ? (
                        <>
                            <ReturnedBag width={24} height={24} />
                            <meta itemProp="availability" content="https://schema.org/PreOrder" />
                            <span>Товар под заказ</span>
                        </>
                    ) : (
                        <>
                            <CheckmarkBag width={24} height={24} />
                            <meta itemProp="availability" content="https://schema.org/InStock" />
                            <span>
                                В наличии {offer?.quantity}{' '}
                                {price_unit === PRICE_UNITS.COIL ? (
                                    declOfNum(quantity, ['бухта', 'бухты', 'бухт'])
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: PRICE_UNITS_ENUM_VALUES[price_unit],
                                        }}
                                    />
                                )}
                            </span>
                        </>
                    )}
                </div>

                <Media query={{ minWidth: layout?.breakpoints.md || 1024 }} defaultMatches>
                    {matches =>
                        matches ? (
                            <Counter
                                name="qty"
                                label=""
                                isFullWidth
                                initialValue={productBasketQty}
                                value={productBasketQty}
                                max={offer?.quantity}
                                step={fromKopecksToRouble(multiple)}
                                onChange={changeQtyHandler}
                                css={storeCounterContainer(smMin, sm)}
                            />
                        ) : (
                            <Counter
                                name="qty"
                                label=""
                                initialValue={productBasketQty}
                                value={productBasketQty}
                                max={offer?.quantity}
                                step={fromKopecksToRouble(multiple)}
                                onChange={changeQtyHandler}
                                css={storeCounterContainer(smMin, sm)}
                            />
                        )
                    }
                </Media>
            </div>
        </div>
    );
};

export default StoreCard;
