import { useCallback, useEffect, useState } from 'react';
import Media from 'react-media';

import { OfferCardItem } from '@context/cart';

import Popup from '@components/controls/Popup';

import { useMassAddToBasketHandler } from '@hooks/useMassAddToBasketHandler';

import { YANDEX_COUNTER_ID } from '@scripts/data/different';
import { PRICE_UNITS_ENUM_VALUES } from '@scripts/enums';
import { Button, useTheme } from '@scripts/gds';
import { fromKopecksToRouble } from '@scripts/helpers';
import { useMedia } from '@scripts/hooks';

import ProductStoreCard from '../ProductStoreCard/ProductStoreCard';
import ProductStoresForm from './ProductStoresForm';
import {
    popupContainerStoresStyle,
    productStoresFormSubmitStyle,
    productStoresPopupBodyContainerBodyStyle,
    productStoresPopupBodyContainerContainerContainerStyle,
    productStoresPopupBodyContainerContainerStyle,
    productStoresPopupBodyContainerStyle,
    productStoresPopupBodyStyle,
    productStoresPopupStyle,
} from './styles';
import { ProductStoresPopupProps } from './type';

const ProductStoresPopup = ({ isOpen, closeHandler, product, basketProducts }: ProductStoresPopupProps) => {
    const { xs, md, sm } = useMedia();
    const { layout } = useTheme();
    const [isAddProducts, setIsAddProducts] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<OfferCardItem[]>([]);
    const mediaQuery = { maxWidth: layout?.breakpoints.xs || 568 };

    const massAddToBasket = useMassAddToBasketHandler();

    const submitBasket = useCallback(() => {
        if (typeof window.ym !== undefined && typeof window.ym === 'function') {
            window.ym(YANDEX_COUNTER_ID, 'reachGoal', 'move_to_basket');
        }
        massAddToBasket(selectedProduct);
        setSelectedProduct([]);
        closeHandler();
    }, [selectedProduct]);

    useEffect(() => {
        if (basketProducts) setIsAddProducts(basketProducts.length > 0);
    }, [basketProducts]);

    return (
        <Media query={mediaQuery} defaultMatches={false}>
            {matches => (
                <Popup
                    isSwipeable
                    isNewStylePopupmobile
                    isOpen={isOpen}
                    scrollInside
                    onRequestClose={() => {
                        setSelectedProduct([]);
                        closeHandler();
                    }}
                    css={productStoresPopupStyle(md, xs)}
                    style={popupContainerStoresStyle(matches)}
                    isDetailOffers
                >
                    <Popup.Body css={productStoresPopupBodyStyle()}>
                        <div css={productStoresPopupBodyContainerStyle}>
                            <div css={productStoresPopupBodyContainerContainerStyle}>
                                <div css={productStoresPopupBodyContainerContainerContainerStyle}>
                                    <Media query={{ minWidth: layout?.breakpoints.sm || 768 }} defaultMatches>
                                        <ProductStoreCard productItem={product} />
                                    </Media>
                                    <ProductStoresForm
                                        product={product}
                                        setIsAddProducts={setIsAddProducts}
                                        basketProducts={basketProducts}
                                        setSelectedProduct={setSelectedProduct}
                                    />
                                </div>
                            </div>
                        </div>
                    </Popup.Body>
                    <Popup.Footer css={{ padding: 0 }}>
                        <div css={productStoresPopupBodyContainerBodyStyle(md, xs)}>
                            <Button
                                data-testid="button-submit"
                                type="submit"
                                theme="secondary"
                                css={productStoresFormSubmitStyle(md, sm)}
                                disabled={!(isAddProducts || basketProducts?.length !== 0)}
                                onClick={submitBasket}
                            >
                                Подтвердить
                            </Button>
                            {fromKopecksToRouble(product.multiple) !== 1 && (
                                <p>
                                    Кратность товара:{' '}
                                    <span>
                                        {fromKopecksToRouble(product?.multiple)}{' '}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: PRICE_UNITS_ENUM_VALUES[product.price_unit],
                                            }}
                                        ></span>
                                    </span>
                                </p>
                            )}
                        </div>
                    </Popup.Footer>
                </Popup>
            )}
        </Media>
    );
};

export default ProductStoresPopup;
