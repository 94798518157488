import { Dispatch, SetStateAction, useMemo } from 'react';
import { useCityStore } from 'src/store';

import { BasketProduct } from '@api/basket/types';
import { ProductDetail, useProductsSearch } from '@api/catalog';

import { OfferCardItem } from '@context/cart';

import Loader from '@controls/Loader';

import { typography } from '@scripts/gds';
import { fromKopecksToRouble } from '@scripts/helpers';
import { useMedia } from '@scripts/hooks';

import StoreCard from './StoreCard';
import {
    productStoresContainerListStoreStyle,
    productStoresCostStyle,
    productStoresDescriptionStyle,
    productStoresHeaderStyle,
} from './styles';

const ProductStoresForm = ({
    product,
    setIsAddProducts,
    basketProducts,
    setSelectedProduct,
}: {
    product: ProductDetail;
    basketProducts: OfferCardItem[] | BasketProduct[] | undefined;
    setIsAddProducts: Dispatch<SetStateAction<boolean>>;
    setSelectedProduct: Dispatch<SetStateAction<OfferCardItem[]>>;
}) => {
    const currentCity = useCityStore(({ currentCity }) => currentCity);
    const { offers, multiple } = product;
    const { sm } = useMedia();

    const offersIds = offers?.map(offer => offer.id);

    // Склады должны быть отсортированы по близости пользователя к складу, запрашиваем сортированные склады
    const { data: sortedOffers, isLoading: isLoading } = useProductsSearch(
        {
            location: {
                lat: Number(currentCity.geo_lat),
                lon: Number(currentCity.geo_lon),
            },
            filter: {
                id: offersIds!,
            },
            sort: ['location'],
            pagination: {
                type: 'offset',
                offset: 0,
                limit: 50,
            },
            include: ['store'],
        },
        Boolean(offersIds && currentCity.geo_lon && currentCity.geo_lat)
    );

    const sortedOffersData = useMemo(() => sortedOffers?.data || [], [sortedOffers]);

    return (
        <div>
            <header css={productStoresHeaderStyle(sm)}>
                <h3 css={typography('productOffersPopup')}>Доступные склады</h3>
                <div css={productStoresCostStyle}>{sortedOffersData?.length} шт</div>
            </header>
            <div>
                <div css={productStoresDescriptionStyle(sm)}>
                    У продавца доступна доставка с разных складов. Выберите необходимое количество товара с одного или
                    сразу с нескольких складов
                </div>
                <div css={productStoresContainerListStoreStyle}>
                    {isLoading ? (
                        <Loader css={{ position: 'static' }} />
                    ) : (
                        sortedOffersData &&
                        sortedOffersData?.length > 0 &&
                        sortedOffersData?.map(offer => {
                            if (offer.quantity < fromKopecksToRouble(multiple)) return null;

                            return (
                                <StoreCard
                                    productDataState={product}
                                    productsBasket={basketProducts}
                                    offer={offer}
                                    setIsAddProducts={setIsAddProducts}
                                    isPreOrder={offer.is_pre_order}
                                    setSelectedProduct={setSelectedProduct}
                                />
                            );
                        })
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductStoresForm;
